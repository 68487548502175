import { browser } from '$app/environment';

/** @param {import('@sentry/sveltekit').Breadcrumb} breadcrumb */
export function getLogData(breadcrumb) {
  const { message, event_id, level, category, data } = breadcrumb;

  /** @type {LogData} */
  const logData = {
    level,
    service: 'adabots-net-website',
    message: `Sentry ${browser ? 'Browser' : 'Server'} hook: ${message || 'undefined message'}`,
    route: browser ? location.href : undefined,
    extra: { event_id, category, ...data },
  };

  return logData;
}

export const ignoreTransactions = ['/log-service', '/sse/luanti-logs', 'wp-'];
