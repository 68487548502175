import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import { getLogData, ignoreTransactions } from './lib/log/sentry';
import { dev } from '$app/environment';
import { isIgnored } from '$lib/log/ignore';

Sentry.init({
  beforeBreadcrumb,
  dsn: 'https://aa592ed6b59c1cc72729f292aa2ef2e0@o4507644557328385.ingest.de.sentry.io/4507644567224400',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],

  environment: PUBLIC_ENVIRONMENT,
  enabled: process.env.NODE_ENV == 'production',
  ignoreTransactions,
});

/** @param {import('@sentry/sveltekit').Breadcrumb} breadcrumb */
export function beforeBreadcrumb(breadcrumb) {
  const { level, category } = breadcrumb;
  if (
    level == 'debug' ||
    category == 'sentry.event' ||
    category == 'sentry.transaction' ||
    (level == 'log' && category == 'console') ||
    (!level && category == 'fetch') ||
    isIgnored(JSON.stringify(breadcrumb))
  )
    return null;

  fetch('/log-service', {
    body: JSON.stringify(getLogData(breadcrumb)),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return dev ? null : breadcrumb;
}

export const handleError = handleErrorWithSentry();
