export const ignoreLines = [
  //<--- avoid invinit loops
  'log-service',
  'https://hooks.slack.com/services',
  'Found ignore text',
  //--->
  // happens probebly after after deployments
  'TypeError: Failed to fetch dynamically imported module',
  // probebly cyber attack
  "SvelteKitError: No action with name 'default' found",
  'SvelteKitError: Form actions expect form-encoded data',
  // code server editor start check on /workspaces
  '.main/apps/code-server',
  // google stuff
  'google-analytics',
  // sentry client warning in dev mode
  'history.pushState(...)',
];

/** @param {string} error */
export function isIgnored(error) {
  return !!ignoreLines.find((message) => error.includes(message));
}
